<template>
    <div>
        <b-row class="mb-4">
            <b-col cols="9">
                <b-card>
                    <b-row>
                        <b-col>
                            <h4>PPE Request</h4>
                        </b-col>
                    </b-row>
                    <ppeDisplayList :isDisplayingForSuper="false"/>
                </b-card>
            </b-col>
            <b-col cols="3">
                <b-card>
                    <b-row>
                        <!-- <b-col class="p-0" cols="12">
                        <data-label :data="selectedPpeRequest.status" 
                            :labelName="'Status:'" :labelRight="false"  /></b-col>
                        <b-col class="p-0" cols="12">
                        <data-label :data="selectedPpeRequest.requestDate | dateTimeFilter" 
                            :labelName="'Requested Date:'" :labelRight="false"  /></b-col>
                        <b-col class="p-0" cols="12">
                        <data-label :data="selectedPpeRequest.approvedBy === '' ? 'N/A' : selectedPpeRequest.approvedBy" 
                            :labelName="'Signed By:'" :labelRight="false"  /></b-col>
                        <b-col class="p-0" cols="12">
                        <data-label :data="selectedPpeRequest.approved === '' ? 'N/A' : selectedPpeRequest.approved" 
                            :labelName="'Signed Date:'" :labelRight="false"  /></b-col>
                        <b-col class="p-0" cols="12">
                        <data-label :data="selectedPpeRequest.rejectionReason" 
                            :labelName="'Rejection Reason:'" :labelRight="false"  /></b-col> -->

                            
                        <b-col class="p-0" cols="12">
                        <data-label :data="selectedPpeRequest.status" 
                            :labelName="'Status:'" :labelRight="false"  /></b-col>
                        <b-col class="p-0" cols="12">
                        <data-label :data="selectedPpeRequest.requestDate | dateTimeFilter" 
                            :labelName="'Requested Date:'" :labelRight="false"  /></b-col>
                        <b-col class="p-0" cols="12">
                        <data-label :data="selectedPpeRequest.createdBy" 
                            :labelName="'Requested By:'" :labelRight="false"  /></b-col>
                        <b-col class="p-0" cols="12">
                        <data-label :data="selectedPpeRequest.createdFor" 
                            :labelName="'Requested For:'" :labelRight="false"  /></b-col>

                        <b-col class="p-0" cols="12" v-if="selectedPpeRequest.status !== 'New'">
                        <data-label :data="selectedPpeRequest.approveDate | dateTimeFilter" 
                            :labelName="'Acknowledged Date:'" :labelRight="false"  /></b-col>
                        <b-col class="p-0" cols="12" v-if="selectedPpeRequest.status !== 'New'">
                        <data-label :data="selectedPpeRequest.approvedBy" 
                            :labelName="'Acknowledged By:'" :labelRight="false"  /></b-col>

                        <b-col class="p-0" cols="12" v-if="selectedPpeRequest.status === 'Recieved'">
                        <data-label :data="selectedPpeRequest.dateReceived | dateTimeFilter" 
                            :labelName="'Signed Date:'" :labelRight="false"  /></b-col>
                        <b-col class="p-0" cols="12">

                        <data-label :data="selectedPpeRequest.rejectionReason"  v-if="selectedPpeRequest.status !== 'New'"
                            :labelName="'Rejection Reason:'" :labelRight="false"  /></b-col>


                        <b-col cols="12" v-if="selectedPpeRequest.status === 'New' && rejectedItems > 0">
                            <label class="bold">Rejection Reason</label>
                        </b-col>
                        <b-col cols="12" v-if="selectedPpeRequest.status === 'New' && rejectedItems > 0">
                            <b-form-textarea v-model="selectedPpeRequest.rejectionReason"></b-form-textarea>
                        </b-col>
                        
                        <b-col cols="12" v-if="selectedPpeRequest.status === 'Acquiring Items'" class="mt-2">
                            <b-button variant="primary" block size="sm" @click="doReadyForPickup()">
                                <b-spinner small v-if="state === 'loading'"></b-spinner> Ready For Pickup</b-button>
                        </b-col>

                        <b-col class="p-0" cols="12" v-if="selectedPpeRequest.status === 'Received'">
                        <data-label :data="selectedPpeRequest.dateReceived | dateTimeFilter" 
                            :labelName="'Date Received:'" :labelRight="false"  /></b-col>

                        <b-col cols="12" v-if="selectedPpeRequest.status === 'Ready For Pickup'" class="mt-2">
                            <b-button variant="primary" block size="sm" @click="doPickup()">Received Items</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import ppeDisplayList from '@/components/ppeDisplayList.vue'
import { mapActions, mapState } from 'vuex'


export default {
  components:{
    ppeDisplayList
  },
  created(){
      if (this.selectedPpeRequest === null) {
          this.back()
      }
  },
  methods: {
      ...mapActions(['setRequestAsCollected']),
      doPickup(){
          this.setRequestAsCollected()
          .then(() => {
              this.back()
          })
      },
      back(){
          this.$router.back()
      }
  },
  computed: {
      ...mapState(['selectedPpeRequest'])
  }
    
}
</script>