<template>
    <div>
        <b-row>
            <b-col>
    
                <b-table striped hover :items="tablePerishableData.dataSource" :fields="tablePerishableData.tableColumns" 
                    :busy="tablePerishableData.isLoading" small sort-icon-left>
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                        </div>
                    </template>
                    <template #cell(description)="row">
                        <b-row align-v="center">
                            <b-icon-eye v-if="row.item.imageDocumentId !== 0" size="lg" @click="openModalImage(row.item.image)" class="mr-2"></b-icon-eye>
                            <span>{{row.item.description}}</span>
                        </b-row>
                    </template>

                    <template #cell(serialNumber)="row">
                        <b-row align-v="center" v-if="isDisplayingForSuper && selectedPpeRequest.status === 'Acquiring Items'">
                            
                            <b-form-input v-model="row.item.serialNumber"></b-form-input>
                        </b-row>
                        <b-row v-else>
                            {{row.item.serialNumber}}
                        </b-row>
                    </template>
                    <template #cell(expiryDate)="row">
                        <b-row align-v="center" v-if="isDisplayingForSuper && selectedPpeRequest.status === 'Acquiring Items'">
                            
                            <b-form-datepicker v-model="row.item.expiryDate"></b-form-datepicker>
                        </b-row>
                        <b-row v-else>
                            <span class="mr-auto">{{row.item.expiryDate | dateTimeFilter}}</span>
                        </b-row>
                    </template>
                    <template #cell(approved)="row">
                        <b-row align-v="center">
                            <b-form-checkbox v-if="isDisplayingForSuper"
                                v-model="row.item.approved"
                                :value="true"
                                @change="requiredFieldMarked(row.item.approved)"
                                :disabled="(selectedPpeRequest.status !== 'New')"
                                :unchecked-value="false">
                                Accepted
                            </b-form-checkbox>
                            <span v-if="!isDisplayingForSuper">{{row.item.approved ? 'Approved' : 'Denied'}}</span>
                        </b-row>
                    </template>

                </b-table>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table striped hover :items="tableData.dataSource" :fields="tableData.tableColumns" 
                :busy="tableData.isLoading" small sort-icon-left>
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                        </div>
                    </template>
                    <template #cell(description)="row">
                        <b-row align-v="center">
                            <b-icon-eye v-if="row.item.imageDocumentId !== 0" size="lg" @click="openModalImage(row.item.image)" class="mr-2"></b-icon-eye>
                            <span>{{row.item.description}}</span>
                        </b-row>
                    </template>

                    <template #cell(dateRecieved)="row">
                        <b-row align-v="center">
                            <span class="mr-auto">{{row.item.dateRecieved | dateTimeFilter}}</span>
                        </b-row>
                    </template>
                    <template #cell(size)="row">
                        <b-row align-v="center">
                            <span class="mr-auto">{{row.item.size !== '' && row.item.size !== null ? row.item.size : 'N/A'}}</span>
                        </b-row>
                    </template>
                    <template #cell(quantity)="row">
                        <b-row align-v="center">
                            <span class="mr-auto">{{row.item.quantity !== '' && row.item.quantity !== null ? row.item.quantity : '0'}}</span>
                        </b-row>
                    </template>
                    <template #cell(approved)="row">
                        <b-row align-v="center">
                            <b-form-checkbox v-if="isDisplayingForSuper"
                                v-model="row.item.approved"
                                @change="requiredFieldMarked(row.item.approved)"
                                :value="true"
                                :disabled="(selectedPpeRequest.status !== 'New')"
                                :unchecked-value="false">
                                Accepted
                            </b-form-checkbox>
                            <span v-if="!isDisplayingForSuper">{{row.item.approved ? 'Approved' : 'Denied'}}</span>
                        </b-row>
                    </template>
                    
                </b-table>
            </b-col>
        </b-row>
        
        <b-modal id="show-image-modal" hide-footer size="xl" no-header @close="closeSearchModal()">
            <b-row>
                <b-col>
                    <img v-if="modalImage.base64Data !== null" :src="returnFileObject(modalImage)" alt="" 
                        class="modal-images">
                    <p class="text-center" v-if="modalImage.base64Data === null" >Could not load image</p>
                </b-col>
            </b-row>
        </b-modal>
        
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { bus } from '../main'
export default {
    props: {
        isDisplayingForSuper: Boolean
    },
    data: () => ({
        tablePerishableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: []
        },
        modalImage: {
            base64Data: null
        },
        superViewTablePerishableData: [
            {
                label: 'Description',
                key: 'description',
                sortable: false,
                tdClass: ''
            },
            {
                label: 'Serial Number',
                key: 'serialNumber',
                sortable: false,
                tdClass: ''
            },
            {
                label: 'Size',
                key: 'size',
                sortable: false,
                tdClass: ''
            },
            {
                label: 'Expiry Date',
                key: 'expiryDate',
                sortable: false,
                tdClass: ''
            },
            {
                label: 'Approve',
                key: 'approved',
                sortable: false,
                tdClass: ''
            }
        ],
        superViewTableColumns: [
            {
                label: 'Description',
                key: 'description',
                sortable: true,
                tdClass: ''
            },
            {
                label: 'Quantity',
                key: 'quantity',
                sortable: false,
                tdClass: ''
            },
            {
                label: 'Received Date',
                key: 'dateRecieved',
                sortable: false,
                tdClass: ''
            },
            {
                label: 'Size',
                key: 'size',
                sortable: false,
                tdClass: ''
            },
            {
                label: 'Approve',
                key: 'approved',
                sortable: false,
                tdClass: ''
            }
        ],
        notSuperViewTablePerishableData: [
            {
                label: 'Description',
                key: 'description',
                sortable: false,
                tdClass: ''
            },
            {
                label: 'Serial Number',
                key: 'serialNumber',
                sortable: false,
                tdClass: ''
            },
            {
                label: 'Size',
                key: 'size',
                sortable: false,
                tdClass: ''
            },
            {
                label: 'Expiry Date',
                key: 'expiryDate',
                sortable: false,
                tdClass: ''
            },
            {
                label: 'Approve',
                key: 'approved',
                sortable: false,
                tdClass: ''
            }
        ],
        notSuperViewTableColumns: [
            {
                label: 'Description',
                key: 'description',
                sortable: true,
                tdClass: ''
            },
            {
                label: 'Quantity',
                key: 'quantity',
                sortable: false,
                tdClass: ''
            },
            {
                label: 'Received Date',
                key: 'dateRecieved',
                sortable: false,
                tdClass: ''
            },
            {
                label: 'Size',
                key: 'size',
                sortable: false,
                tdClass: ''
            },
            {
                label: 'Approve',
                key: 'approved',
                sortable: false,
                tdClass: ''
            }
        ],
        tableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: []
        }
    }),
    created() {
        this.tableData.tableColumns = this.isDisplayingForSuper === true ? this.superViewTableColumns : this.notSuperViewTableColumns
        this.tablePerishableData.tableColumns = this.isDisplayingForSuper === true ? this.superViewTablePerishableData : this.notSuperViewTablePerishableData

        this.getPpeRequest()
        .then((response) => {
            this.selectedPpeRequest = response.data.result
            let replenishable = []
            let nonreplenishable = []
            response.data.result.ppEitems.forEach(item => {
                if (item.perishable) {
                    nonreplenishable.push(item)
                }
                else {
                    replenishable.push(item)
                }
            });
            this.selectedPpeRequest.replenishable = replenishable
            this.selectedPpeRequest.nonreplenishable = nonreplenishable
            this.tableData.dataSource = this.selectedPpeRequest.replenishable
            this.tablePerishableData.dataSource = this.selectedPpeRequest.nonreplenishable
            this.tableData.isLoading = false
            this.tablePerishableData.isLoading = false
        })
    },
    methods: {
        ...mapActions(['getPpeRequest', 'getAvailableItems']),
        requiredFieldMarked(approvedItem){
            bus.$emit('ppeRequestCountDeniedItems', approvedItem)
        },
        returnFileObject(item){
            let base64 = 'data:' + item.contentType +';base64,'+ item.base64Data
            return base64
        },
        openModalImage(image){
            //console.log('image', image)
            this.modalImage = image
            this.$root.$emit('bv::show::modal', 'show-image-modal', '#btnShow')
        },
    },
    mutations: {
      ...mapState(['selectedPpeRequest'])
    }
}
</script>